import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import pt from './locales/pt.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    pt: { translation: pt }
  },
  lng: 'pt', // Idioma padrão (pode ser alterado para 'en' ou 'pt')
  fallbackLng: 'en', // Caso algum texto não tenha tradução
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
