import React from 'react';
import { Container, Typography } from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Sobre Fe e Escrita
      </Typography>
      <Typography variant="body1">
        O blog Fe e Escrita é um espaço dedicado à reflexão sobre a fé cristã e seus ensinamentos. Aqui, compartilhamos mensagens de esperança, perseverança e amor, inspiradas pela Bíblia. Nosso objetivo é fortalecer a caminhada espiritual de nossos leitores por meio de textos que abordam temas do cotidiano sob a ótica da fé.
      </Typography>
    </Container>
  );
};

export default About;
