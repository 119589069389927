import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, TextField, Button, InputAdornment } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import './App.css';
import PostDetails from './PostDetails';
import './i18n';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import AdComponent from './AdComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagUsa, faFlag, faSearch } from '@fortawesome/free-solid-svg-icons';

function App() {
  const { t, i18n } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Carregar posts a partir da API do backend
  useEffect(() => {
    fetch('https://feescrita-138417386089.us-central1.run.app/api/posts')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao buscar posts do backend');
        }
        return response.json();
      })
      .then((data) => {
        const reversedPosts = data.reverse();  // Inverter a ordem dos posts
        setPosts(reversedPosts);
        setFilteredPosts(reversedPosts);
      })
      .catch((error) => {
        console.error('Erro ao carregar os posts:', error);
      });
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = posts.filter(post => post.title.toLowerCase().includes(query));
    setFilteredPosts(filtered);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleAcceptCookies = () => {
    console.log('Usuário aceitou os cookies');
    // Carregar Google Analytics e AdSense aqui...
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=G-4B431TTMT2`;
    gtagScript.async = true;
    document.body.appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-4B431TTMT2');

    // Carregar Google AdSense
    const adsenseScript = document.createElement('script');
    adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    adsenseScript.async = true;
    adsenseScript.setAttribute('data-ad-client', 'ca-pub-3458489837498636');
    document.body.appendChild(adsenseScript);
  };

  return (
    <Router>
      <div className="App">
        <AppBar position="static">
          <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
        {/* Imagem/desenho ao lado do título */}
        <img
          src="/feescrita.jpg"  // Certifique-se de que o caminho da imagem está correto
          alt="Ícone Fé Escrita"
          style={{ width: '80px', height: '80px', marginRight: '10px' }}
        />

        {/* Palavra Fé Escrita como link */}
        <Typography variant="h6" component={Link} to="/" style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold', fontSize: '2.5rem' }}>
          Fé Escrita
        </Typography>

        {/* Espaço para empurrar os botões de navegação para a direita */}
        <div style={{ flexGrow: 1 }} />

        <Button color="inherit" href="https://www.youtube.com/@CoracaoCristao-go7ms" target="_blank" rel="noopener noreferrer">
          Coração Cristão
        </Button>
        <Button color="inherit" href="https://cmaonline.com.br/" target="_blank" rel="noopener noreferrer">
          Corpo, Mente & Alma
        </Button>
        <Button color="inherit" component={Link} to="/about">
          Sobre
        </Button>
        <Button color="inherit" component={Link} to="/privacy-policy">
          Política de Privacidade
        </Button>
      </Toolbar>
        </AppBar>

        <Container maxWidth="lg" style={{marginTop: '20px'}}>
          <TextField
              fullWidth
              label={t('search_placeholder')}
              variant="outlined"
              value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: '20px', borderRadius: '25px', padding: '5px' }}
          />
        </Container>

        <Routes>
          <Route
            exact
            path="/"
            element={
              <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12}>
                   {filteredPosts.map(post => (
                      <Paper key={post.id} style={{ padding: '20px', marginBottom: '30px', borderRadius: '15px' }}>
                        <Typography variant="h5" component="h2">
                          <Link to={`/posts/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {post.title}
                          </Link>
                        </Typography>
                        <Typography variant="body1">
                          {post.content.substring(0, 100)}...
                        </Typography>
                        {/* Agora a imagem é exibida logo após o conteúdo */}
                        <img  src={`https://storage.googleapis.com/feescrita-posts-bucket/${post.id}.jpg`}
                              alt={post.title}
                              style={{ width: '100%', height: 'auto', borderRadius: '10px', marginTop: '20px' }} />
                      </Paper>
                    ))}
                  </Grid>

                  {/*<Grid item xs={12} md={4}>
                    <Paper style={{ padding: '20px', marginBottom: '20px' }}>
                      <Typography variant="h6" component="div">
                        {t('ads_title')}
                      </Typography>
                      <div style={{ marginTop: '20px' }}>
                        <AdComponent />
                      </div>
                    </Paper>
                  </Grid>*/}
                </Grid>
              </Container>
            }
          />
          <Route path="/posts/:id" element={<PostDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        <footer style={{ padding: '10px', marginTop: '20px', backgroundColor: '#282c34', color: 'white', textAlign: 'center' }}>
          <Typography variant="body1">
            {t('copyright')}
          </Typography>
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="Aceitar Cookies"
          declineButtonText="Recusar"
          onAccept={handleAcceptCookies}
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        >
          Este site utiliza cookies para melhorar sua experiência...
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
