import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Política de Privacidade
      </Typography>
      <Typography variant="body1">
        No blog Fe e Escrita, respeitamos sua privacidade e estamos comprometidos em proteger suas informações pessoais. Esta política de privacidade explica como coletamos, usamos e protegemos seus dados.
        <br /><br />
        **Coleta de Informações**: Coletamos informações que você nos fornece diretamente, como quando se inscreve em nossa newsletter ou envia um comentário.
        <br /><br />
        **Uso de Informações**: Usamos as informações coletadas para melhorar nossos serviços, enviar atualizações e personalizar a experiência dos usuários no blog.
        <br /><br />
        **Proteção de Dados**: Implementamos medidas de segurança para proteger suas informações contra acessos não autorizados. No entanto, nenhum método de transmissão de dados na internet é completamente seguro.
        <br /><br />
        **Cookies**: Utilizamos cookies para personalizar o conteúdo e analisar o tráfego no site. Você pode desativar os cookies ajustando as configurações do seu navegador.
        <br /><br />
        **Alterações na Política de Privacidade**: Podemos alterar esta política periodicamente. Recomendamos que você revise esta página regularmente para ficar informado sobre qualquer alteração.
        <br /><br />
        Para mais informações, entre em contato conosco através do email contato@feescrita.com.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
